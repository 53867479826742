<template>
    <!-- 界面头 -->

    <div>
        <div class="mianheader">
            
        </div>
        <div class="mianbody">
         
        </div>

    </div>
</template>


<script>
export default {
    data() {
        return {
            file_items: [], // 存储文件
            files_items: [],//储存文件夹
            currentDirPath: 'D:\\\\', // 当前目录路径
            userName: '',
            showInfo: false,//初始化显示页面
        };
    },
    created() {
        // 在组件创建时获取用户名  
        this.getUserName();
    },
    methods: {
        // 获取到用户名称来作为根目录
        getUserName() {
            const token = localStorage.getItem('token');
            this.$http.get('/getUsername', {
                headers: {
                    'token': token // 在这里添加token到header中  
                }
            })
                .then(response => {
                    this.userName = response.data.data
                    this.currentDirPath = this.currentDirPath + response.data.data
                    console.log(this.currentDirPath)
                    this.getDataFile()
                })
                .catch(error => {
                    console(error)
                })
        },
        // 查询数据库里面的文件信息
        getDataFile() {
            console.log(this.currentDirPath)
            const token = localStorage.getItem('token');
            this.$http.get('/getDataFile', {
                params: {
                    currentDirPath: this.currentDirPath
                },
                headers: {
                    'token': token // 在这里添加token到header中  
                }
            })
                .then(response => {
                    console.log(response.data.data)
                    this.file_items = response.data.data
                })
                .catch(error => {
                    console(error)
                })
        },
        // 查询数据库里面的文件夹信息
        getDataFiles() {
            const token = localStorage.getItem('token');
            this.$http.get('/getDataFiles', this.currentDirPath, {
                headers: {
                    'token': token // 在这里添加token到header中  
                }
            })
                .then(response => {
                    this.files_items = response.data.data
                })
                .catch(error => {
                    console(error)
                })
        },
    }
}
</script>

<style>
/* Add your custom styles here */
.head {
    display: flex;
    position: relative;
}

.header1 {
    line-height: 20px;
    position: absolute;
}

.header2 {
    right: 0%;
    position: absolute;
}

.el-header,
.el-footer {
    background-color: #B3C0D1;
    color: #303133;
    text-align: center;
    line-height: 60px;
}

.el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 800px;
}

.el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    display: flex;
    position: relative;
}

body>.el-container {
    margin-bottom: 40px;
}

.mainheader {
    display: block;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
}

.mianbody {
    position: absolute;
    margin: 0px;
    left: 0%;
}

.mianbody ul {
    list-style: none;
}

</style>