import Vue from 'vue'
import VueRouter from 'vue-router'
import MainDisk from '@/views/users/MainDisk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/users/LoginView.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/users/IndexView.vue'),
    children: [
      {
        path: '/maindisk',
        name: 'maindisk',
        component: MainDisk
      }
    ]
  },
  // {
  //   path: '/maindisk',
  //   name: 'maindisk',
  //   component: MainDisk
  // },
]

const router = new VueRouter({
  routes
})

export default router
